.map {

  img {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;

    @media (min-width: $screen-md) {
      margin-top: 40px;
    }

    @media (min-width: $screen-lg) {
      margin-top: 0;
    }
  }

  h2 {
    margin-bottom: 30px;

    @media (min-width: $screen-ws) {
      margin-bottom: 60px;
    }
  }

  a, a:visited {
    color: $color-secondary;

    &:hover, &:focus {
      color: $color-primary;
    }
  }
}