footer {
  padding: 40px 0px;

  @media (min-width: $screen-md) {
    padding: 30px 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    @media (min-width: $screen-md) {
      display: flex;
      text-align: left;
    }

    & > li {
      margin-bottom: 20px;

      @media (min-width: $screen-md) {
        margin-right: 15px;
        margin-bottom: 0;
      }
      @media (min-width: $screen-lg) {
        margin-right: 30px;
      }
      @media (min-width: $screen-ws) {
        margin-right: 50px;
      }

      &:last-child {
        margin-bottom: 0px;
        margin-right: 0px;
      }


    }
  }

  p {

    text-align: center;
    margin-bottom: 40px;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-md) {
      text-align: left;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
  }

  a, a:visited {
    color: $text-color-default;
    font-weight: 500;

    &:hover, &:focus {
      color: $color-secondary;
    }
  }
}