#maintenance-page {
  color: #fff;
  background-color: #7a8488;
  background-image: url("../images/maintenance/bicycle.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  h1, h2, h3, h4, h5, h6, p, ul, li {
    color: #fff;
  }

  .content-container {
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: rgba(0,0,0,0.75);
    backdrop-filter: blur(3px);
  }

  h1 {
    span {
      font-size: 2rem;
      display: block;
      line-height: 2;
      margin-bottom: 3rem;
    }
  }
}