.about {

  h2 {
    margin-left: auto;
    margin-right: auto;
  }

  &__desc {

    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 35px;
    }

    @media (min-width: $screen-lg) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }

    @media (min-width: $screen-xl) {
      max-width: 70%;
      margin-bottom: 45px;
    }

    @media (min-width: $screen-ws) {
      max-width: 66%;
      margin-bottom: 60px;
    }
  }

  &__main-icon {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;

    svg {
      width: 30px;
      height: 30px;
    }

    @media (min-width: $screen-lg) {
      width: 40px;
      height: 40px;
      margin-bottom: 30px;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    @media (min-width: $screen-xl) {
      width: 45px;
      height: 45px;

      svg {
        width: 45px;
        height: 45px;
      }
    }

    @media (min-width: $screen-ws) {
      width: 50px;
      height: 50px;
      margin-bottom: 40px;

      svg {
        width: 50px;
        height: 50px;
      }
    }

  }

  &__cards {
    @media (min-width: $screen-lg) {
      display: flex;
      align-items: center;
    }

    &--item-title {
      text-transform: uppercase;
      margin-bottom: 10px;

      @media (min-width: $screen-ws) {
        font-size: 20px;
        letter-spacing: 0.5px;
      }
    }
  }

  &__cards--item.swiper-slide {

    background: $color-primary;
    padding: 25px 20px;
    width: 280px;

    @media (max-width: $screen-sm - 1px) {
      height: 244px;
    }

    @media (min-width: $screen-sm) {
      width: 380px;
      padding: 30px 25px;
    }

    @media (min-width: $screen-md) {
      width: 400px;
      padding: 35px 30px;
    }

    @media (min-width: $screen-lg) {
      padding: 45px 25px;
      width: calc(33% + 15px);
    }

    @media (min-width: $screen-xl) {
      padding: 50px 25px;
    }

    @media (min-width: $screen-ws) {
      padding: 80px 65px;
    }

    svg {
      width: 25px;
      height: 25px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      display: block;

      @media (min-width: $screen-md) {
        width: 30px;
        height: 30px;
        margin-bottom: 25px;
      }

      @media (min-width: $screen-lg) {
        width: 35px;
        height: 35px;
        margin-bottom: 25px;
      }

      @media (min-width: $screen-xl) {
        width: 40px;
        height: 40px;
      }

      @media (min-width: $screen-ws) {
        width: 50px;
        height: 50px;
        margin-bottom: 30px;
      }
    }
  }

  &__cards--item.slide-two {

    background: $color-secondary;
    padding: 25px 20px;

    @media (min-width: $screen-sm) {
      width: 380px;
      padding: 30px 25px;
    }

    @media (min-width: $screen-md) {
      width: 400px;
      padding: 35px 30px;
    }

    @media (min-width: $screen-lg) {
      width: calc(33% - 15px);
      padding: 70px 20px;
    }

    @media (min-width: $screen-xl) {
      padding: 80px 20px;
    }

    @media (min-width: $screen-ws) {
      padding: 130px 50px;
    }
  }

  [data-aos^=fade][data-aos^=fade].aos-init {

    @media (max-width: 991px) {
      transform: none !important;
      opacity: 1 !important;
    }

  }

}

[data-aos=fade-down] {
  transform: translate3d(0, 40px, 0) !important;
}

[data-aos=fade-left] {
  transform: translate3d(40px, 0, 0) !important;
}

[data-aos=fade-down].aos-animate {
  transform: translateZ(0) !important;
}

[data-aos=fade-left].aos-animate {
  transform: translateX(0) !important;
}