.gallery {


  &__wrapper {
    margin-top: 30px;

    @media (min-width: $screen-md) {
      margin-top: 35px;
    }

    @media (min-width: $screen-lg) {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media (min-width: $screen-ws) {
      margin-top: 60px;
    }
  }

  &__item.swiper-slide {
    width: 280px;
    height: 280px;
    overflow: hidden;

    @media (min-width: 380px) {
      width: 310px;
      height: 310px;
    }

    @media (min-width: $screen-sm) {
      width: 350px;
      height: 350px;
    }

    @media (min-width: $screen-md) {
      width: 400px;
      height: 400px;
    }
  }

  &__item {
    overflow: hidden;

    @media (min-width: $screen-lg) {
      width: 290px;
      height: 290px;
    }

    @media (min-width: $screen-xl) {
      width: 350px;
      height: 350px;
    }

    @media (min-width: $screen-ws) {
      width: 530px;
      height: 530px;
    }

    img {
      transform: scale(1);
      transition: transform 400ms ease-in-out;
    }

    &:hover, &:focus {
      img {
        transform: scale(1.1);
        transition: transform 400ms ease-in-out;
      }
    }
  }

  &__item:nth-child(1) {
    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
    }
  }

  &__item:nth-child(2) {
    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
    }

  }

  &__item:nth-child(3) {
    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
    }

  }

  &__item:nth-child(4) {
    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
    }
  }

  &__item:nth-child(5) {
    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
    }

  }

  &__item:nth-child(6) {
    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
    }

  }

  .gallery__desc {
    @media (min-width: $screen-lg) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: $screen-xl) {
      max-width: 70%;
    }
    @media (min-width: $screen-ws) {
      max-width: 66%;
    }
  }

  p.gallery__desc {
    margin-bottom: 0;
  }
}