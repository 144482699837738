ul {
  list-style: disc;
  margin: 0 0 1.2rem 0;
}

li {
  margin-bottom: 0;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}
