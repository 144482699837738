dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

table {
  text-align: center;
  margin: 0 0 1.5em;
  width: 100%;
}

thead th {
  background: $color-soft;
  color: #fff;
  border-bottom: 2px solid #bbb;
  padding-bottom: 0.5em;
}

th {
  padding: 0.4em;
}

tr {
  border-bottom: 1px solid #eee;
}

td {
  padding: 0.4em;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}