body, html {
  font: 400 14px/1.43 $font-family-content;
  color: $text-color-default;

  @media (min-width: $screen-md) {
    font-size: 16px;
  }

  @media (min-width: $screen-ws) {
    font-size: 18px;
  }
}

a, a:visited {
  font-weight: inherit;
  text-decoration: none;
  color: $color-primary;
  transition: .32s color;

  &:hover, &:focus {
    text-decoration: none;
    color: darken($color-primary, 20);
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.25;
  font-family: $font-family-title;
  color: $color-secondary;
}

h1, .h1 {
  position: relative;
  color: $text-color-default;
  margin-bottom: 40px;
  font-size: 28px;
  letter-spacing: 0.7px;
  line-height: 1.21;
  font-weight: 500;

  @media (min-width: $screen-md) {
    font-size: 32px;
    letter-spacing: 0.8px;
  }

  @media (min-width: $screen-lg) {
    font-size: 36px;
    letter-spacing: 0.9px;
    margin-bottom: 25px;
  }

  @media (min-width: $screen-xl) {
    letter-spacing: 1px;
    font-size: 40px;
  }

  @media (min-width: $screen-ws) {
    font-size: 52px;
    letter-spacing: 1.3px;
    margin-bottom: 30px;
  }
}

h2, .h2 {
  position: relative;
  color: $text-color-default;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  margin-bottom: 15px;
  letter-spacing: 0.6px;

  @media (min-width: $screen-md) {
    font-size: 26px;
  }

  @media (min-width: $screen-lg) {
    font-size: 28px;
  }

  @media (min-width: $screen-xl) {
    font-size: 30px;
    letter-spacing: 0.7px;
  }

  @media (min-width: $screen-ws) {
    font-size: 36px;
    letter-spacing: 0.9px;
    margin-bottom: 25px;
  }
}

h3, .h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.4px;
  margin-bottom: 15px;

  @media (min-width: $screen-md) {
    font-size: 18px;
    letter-spacing: 0.3px;
  }

  @media (min-width: $screen-lg) {
    font-size: 20px;
    letter-spacing: 0.1px;
  }

  @media (min-width: $screen-ws) {
    font-size: 22px;
    margin-bottom: 20px;
    letter-spacing: 0px;
  }
}

h4, .h4 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: .75em;
}

h5, h6, .h5, .h6 {
  font-size: 1rem;
  margin-bottom: .75em;
}

strong {
  font-weight: 600;
}

.extra-text {
  position: relative;
  color: rgb(134, 134, 134);
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.008rem;
  text-transform: uppercase;

  &:before {
    content: "";
    border-style: solid;
    border-width: 0 0 1.25rem 1.1rem;
    border-color: transparent transparent #a1c517 transparent;
    position: absolute;
    left: -1.25rem;
    top: -1.25rem;
  }
}

p {
  margin-bottom: 30px;

  @media (min-width: $screen-lg) {
    margin-bottom: 35px;
  }

  @media (min-width: $screen-ws) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

p.cursive-text {
  font-style: italic;
  font-weight: 500;
}

.white-text {
  color: $text-color-light;
}

.small-text {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

section#imprint, section#privacy-policy {
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: $screen-md) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @media (min-width: $screen-xl) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: $screen-ws) {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}