.other {
  background: $color-soft;
  @media (max-width: 991px) {
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
  }

  h2 {
    margin-bottom: 30px;

    @media (min-width: $screen-ws) {
      margin-bottom: 60px;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 35px;
    }
  }

  .btn {
    margin-bottom: 0;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-md) {
      margin-top: 35px;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $screen-lg) {
      margin-top: 40px;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $screen-ws) {
      margin-top: 50px;
    }
  }

  .btn + .btn {
    margin-top: 15px;
    @media (min-width: $screen-md) {
      margin-left: 15px;
      margin-top: 35px;
    }
    @media (min-width: $screen-lg) {
      margin-left: 0px;
      margin-top: 15px;
    }
    @media (min-width: $screen-xl) {
      margin-left: 15px;
      margin-top: 40px;
    }
    @media (min-width: $screen-ws) {
      margin-left: 30px;
      margin-top: 50px;
    }
  }
}