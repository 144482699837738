@charset "UTF-8";
@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: normal;
  src: local(""), url("../fonts/WorkSans-Regular.woff") format("woff");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: italic;
  src: local(""), url("../fonts/WorkSans-Italic.woff") format("woff");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: normal;
  src: local(""), url("../fonts/WorkSans-Medium.woff") format("woff");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: normal;
  src: local(""), url("../fonts/WorkSans-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  font-style: normal;
  src: local(""), url("../fonts/WorkSans-Bold.woff") format("woff");
}
/**
  Main container max width
 */
/*
  Theme breakpoints
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-ws,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1710px) {
  .container-ws, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1680px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-ws,
.col-ws-auto, .col-ws-12, .col-ws-11, .col-ws-10, .col-ws-9, .col-ws-8, .col-ws-7, .col-ws-6, .col-ws-5, .col-ws-4, .col-ws-3, .col-ws-2, .col-ws-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1710px) {
  .col-ws {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-ws-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-ws-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-ws-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-ws-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-ws-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-ws-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-ws-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-ws-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-ws-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-ws-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-ws-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-ws-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-ws-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-ws-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-ws-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-ws-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-ws-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-ws-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-ws-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-ws-first {
    order: -1;
  }

  .order-ws-last {
    order: 13;
  }

  .order-ws-0 {
    order: 0;
  }

  .order-ws-1 {
    order: 1;
  }

  .order-ws-2 {
    order: 2;
  }

  .order-ws-3 {
    order: 3;
  }

  .order-ws-4 {
    order: 4;
  }

  .order-ws-5 {
    order: 5;
  }

  .order-ws-6 {
    order: 6;
  }

  .order-ws-7 {
    order: 7;
  }

  .order-ws-8 {
    order: 8;
  }

  .order-ws-9 {
    order: 9;
  }

  .order-ws-10 {
    order: 10;
  }

  .order-ws-11 {
    order: 11;
  }

  .order-ws-12 {
    order: 12;
  }

  .offset-ws-0 {
    margin-left: 0;
  }

  .offset-ws-1 {
    margin-left: 8.33333333%;
  }

  .offset-ws-2 {
    margin-left: 16.66666667%;
  }

  .offset-ws-3 {
    margin-left: 25%;
  }

  .offset-ws-4 {
    margin-left: 33.33333333%;
  }

  .offset-ws-5 {
    margin-left: 41.66666667%;
  }

  .offset-ws-6 {
    margin-left: 50%;
  }

  .offset-ws-7 {
    margin-left: 58.33333333%;
  }

  .offset-ws-8 {
    margin-left: 66.66666667%;
  }

  .offset-ws-9 {
    margin-left: 75%;
  }

  .offset-ws-10 {
    margin-left: 83.33333333%;
  }

  .offset-ws-11 {
    margin-left: 91.66666667%;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1710px) {
  .flex-ws-row {
    flex-direction: row !important;
  }

  .flex-ws-column {
    flex-direction: column !important;
  }

  .flex-ws-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-ws-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-ws-wrap {
    flex-wrap: wrap !important;
  }

  .flex-ws-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-ws-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-ws-fill {
    flex: 1 1 auto !important;
  }

  .flex-ws-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-ws-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-ws-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-ws-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-ws-start {
    justify-content: flex-start !important;
  }

  .justify-content-ws-end {
    justify-content: flex-end !important;
  }

  .justify-content-ws-center {
    justify-content: center !important;
  }

  .justify-content-ws-between {
    justify-content: space-between !important;
  }

  .justify-content-ws-around {
    justify-content: space-around !important;
  }

  .align-items-ws-start {
    align-items: flex-start !important;
  }

  .align-items-ws-end {
    align-items: flex-end !important;
  }

  .align-items-ws-center {
    align-items: center !important;
  }

  .align-items-ws-baseline {
    align-items: baseline !important;
  }

  .align-items-ws-stretch {
    align-items: stretch !important;
  }

  .align-content-ws-start {
    align-content: flex-start !important;
  }

  .align-content-ws-end {
    align-content: flex-end !important;
  }

  .align-content-ws-center {
    align-content: center !important;
  }

  .align-content-ws-between {
    align-content: space-between !important;
  }

  .align-content-ws-around {
    align-content: space-around !important;
  }

  .align-content-ws-stretch {
    align-content: stretch !important;
  }

  .align-self-ws-auto {
    align-self: auto !important;
  }

  .align-self-ws-start {
    align-self: flex-start !important;
  }

  .align-self-ws-end {
    align-self: flex-end !important;
  }

  .align-self-ws-center {
    align-self: center !important;
  }

  .align-self-ws-baseline {
    align-self: baseline !important;
  }

  .align-self-ws-stretch {
    align-self: stretch !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1710px) {
  .text-ws-left {
    text-align: left !important;
  }

  .text-ws-right {
    text-align: right !important;
  }

  .text-ws-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1710px) {
  .d-ws-none {
    display: none !important;
  }

  .d-ws-inline {
    display: inline !important;
  }

  .d-ws-inline-block {
    display: inline-block !important;
  }

  .d-ws-block {
    display: block !important;
  }

  .d-ws-table {
    display: table !important;
  }

  .d-ws-table-row {
    display: table-row !important;
  }

  .d-ws-table-cell {
    display: table-cell !important;
  }

  .d-ws-flex {
    display: flex !important;
  }

  .d-ws-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1710px) {
  .m-ws-0 {
    margin: 0 !important;
  }

  .mt-ws-0,
.my-ws-0 {
    margin-top: 0 !important;
  }

  .mr-ws-0,
.mx-ws-0 {
    margin-right: 0 !important;
  }

  .mb-ws-0,
.my-ws-0 {
    margin-bottom: 0 !important;
  }

  .ml-ws-0,
.mx-ws-0 {
    margin-left: 0 !important;
  }

  .m-ws-1 {
    margin: 0.25rem !important;
  }

  .mt-ws-1,
.my-ws-1 {
    margin-top: 0.25rem !important;
  }

  .mr-ws-1,
.mx-ws-1 {
    margin-right: 0.25rem !important;
  }

  .mb-ws-1,
.my-ws-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-ws-1,
.mx-ws-1 {
    margin-left: 0.25rem !important;
  }

  .m-ws-2 {
    margin: 0.5rem !important;
  }

  .mt-ws-2,
.my-ws-2 {
    margin-top: 0.5rem !important;
  }

  .mr-ws-2,
.mx-ws-2 {
    margin-right: 0.5rem !important;
  }

  .mb-ws-2,
.my-ws-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-ws-2,
.mx-ws-2 {
    margin-left: 0.5rem !important;
  }

  .m-ws-3 {
    margin: 1rem !important;
  }

  .mt-ws-3,
.my-ws-3 {
    margin-top: 1rem !important;
  }

  .mr-ws-3,
.mx-ws-3 {
    margin-right: 1rem !important;
  }

  .mb-ws-3,
.my-ws-3 {
    margin-bottom: 1rem !important;
  }

  .ml-ws-3,
.mx-ws-3 {
    margin-left: 1rem !important;
  }

  .m-ws-4 {
    margin: 1.5rem !important;
  }

  .mt-ws-4,
.my-ws-4 {
    margin-top: 1.5rem !important;
  }

  .mr-ws-4,
.mx-ws-4 {
    margin-right: 1.5rem !important;
  }

  .mb-ws-4,
.my-ws-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-ws-4,
.mx-ws-4 {
    margin-left: 1.5rem !important;
  }

  .m-ws-5 {
    margin: 3rem !important;
  }

  .mt-ws-5,
.my-ws-5 {
    margin-top: 3rem !important;
  }

  .mr-ws-5,
.mx-ws-5 {
    margin-right: 3rem !important;
  }

  .mb-ws-5,
.my-ws-5 {
    margin-bottom: 3rem !important;
  }

  .ml-ws-5,
.mx-ws-5 {
    margin-left: 3rem !important;
  }

  .p-ws-0 {
    padding: 0 !important;
  }

  .pt-ws-0,
.py-ws-0 {
    padding-top: 0 !important;
  }

  .pr-ws-0,
.px-ws-0 {
    padding-right: 0 !important;
  }

  .pb-ws-0,
.py-ws-0 {
    padding-bottom: 0 !important;
  }

  .pl-ws-0,
.px-ws-0 {
    padding-left: 0 !important;
  }

  .p-ws-1 {
    padding: 0.25rem !important;
  }

  .pt-ws-1,
.py-ws-1 {
    padding-top: 0.25rem !important;
  }

  .pr-ws-1,
.px-ws-1 {
    padding-right: 0.25rem !important;
  }

  .pb-ws-1,
.py-ws-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-ws-1,
.px-ws-1 {
    padding-left: 0.25rem !important;
  }

  .p-ws-2 {
    padding: 0.5rem !important;
  }

  .pt-ws-2,
.py-ws-2 {
    padding-top: 0.5rem !important;
  }

  .pr-ws-2,
.px-ws-2 {
    padding-right: 0.5rem !important;
  }

  .pb-ws-2,
.py-ws-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-ws-2,
.px-ws-2 {
    padding-left: 0.5rem !important;
  }

  .p-ws-3 {
    padding: 1rem !important;
  }

  .pt-ws-3,
.py-ws-3 {
    padding-top: 1rem !important;
  }

  .pr-ws-3,
.px-ws-3 {
    padding-right: 1rem !important;
  }

  .pb-ws-3,
.py-ws-3 {
    padding-bottom: 1rem !important;
  }

  .pl-ws-3,
.px-ws-3 {
    padding-left: 1rem !important;
  }

  .p-ws-4 {
    padding: 1.5rem !important;
  }

  .pt-ws-4,
.py-ws-4 {
    padding-top: 1.5rem !important;
  }

  .pr-ws-4,
.px-ws-4 {
    padding-right: 1.5rem !important;
  }

  .pb-ws-4,
.py-ws-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-ws-4,
.px-ws-4 {
    padding-left: 1.5rem !important;
  }

  .p-ws-5 {
    padding: 3rem !important;
  }

  .pt-ws-5,
.py-ws-5 {
    padding-top: 3rem !important;
  }

  .pr-ws-5,
.px-ws-5 {
    padding-right: 3rem !important;
  }

  .pb-ws-5,
.py-ws-5 {
    padding-bottom: 3rem !important;
  }

  .pl-ws-5,
.px-ws-5 {
    padding-left: 3rem !important;
  }

  .m-ws-n1 {
    margin: -0.25rem !important;
  }

  .mt-ws-n1,
.my-ws-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-ws-n1,
.mx-ws-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-ws-n1,
.my-ws-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-ws-n1,
.mx-ws-n1 {
    margin-left: -0.25rem !important;
  }

  .m-ws-n2 {
    margin: -0.5rem !important;
  }

  .mt-ws-n2,
.my-ws-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-ws-n2,
.mx-ws-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-ws-n2,
.my-ws-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-ws-n2,
.mx-ws-n2 {
    margin-left: -0.5rem !important;
  }

  .m-ws-n3 {
    margin: -1rem !important;
  }

  .mt-ws-n3,
.my-ws-n3 {
    margin-top: -1rem !important;
  }

  .mr-ws-n3,
.mx-ws-n3 {
    margin-right: -1rem !important;
  }

  .mb-ws-n3,
.my-ws-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-ws-n3,
.mx-ws-n3 {
    margin-left: -1rem !important;
  }

  .m-ws-n4 {
    margin: -1.5rem !important;
  }

  .mt-ws-n4,
.my-ws-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-ws-n4,
.mx-ws-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-ws-n4,
.my-ws-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-ws-n4,
.mx-ws-n4 {
    margin-left: -1.5rem !important;
  }

  .m-ws-n5 {
    margin: -3rem !important;
  }

  .mt-ws-n5,
.my-ws-n5 {
    margin-top: -3rem !important;
  }

  .mr-ws-n5,
.mx-ws-n5 {
    margin-right: -3rem !important;
  }

  .mb-ws-n5,
.my-ws-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-ws-n5,
.mx-ws-n5 {
    margin-left: -3rem !important;
  }

  .m-ws-auto {
    margin: auto !important;
  }

  .mt-ws-auto,
.my-ws-auto {
    margin-top: auto !important;
  }

  .mr-ws-auto,
.mx-ws-auto {
    margin-right: auto !important;
  }

  .mb-ws-auto,
.my-ws-auto {
    margin-bottom: auto !important;
  }

  .ml-ws-auto,
.mx-ws-auto {
    margin-left: auto !important;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.btn, input[type=button],
input[type=submit], .btn:visited {
  display: block;
  text-align: center;
  padding: 16px 25px;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
  width: 100%;
  line-height: 1.2;
  color: rgb(255, 255, 255);
}
@media (min-width: 768px) {
  .btn, input[type=button],
input[type=submit], .btn:visited {
    max-width: 250px;
  }
}
@media (min-width: 992px) {
  .btn, input[type=button],
input[type=submit], .btn:visited {
    margin-bottom: 35px;
  }
}
@media (min-width: 1200px) {
  .btn, input[type=button],
input[type=submit], .btn:visited {
    margin-bottom: 40px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }
}
@media (min-width: 1710px) {
  .btn, input[type=button],
input[type=submit], .btn:visited {
    margin-bottom: 50px;
  }
}

.btn-primary, input[type=button],
input[type=submit], .btn-primary:visited {
  background: #125B98;
  transition: background-color 300ms linear, color 300ms linear;
}
.btn-primary:hover, input[type=button]:hover,
input[type=submit]:hover, .btn-primary:focus, input[type=button]:focus,
input[type=submit]:focus, .btn-primary:visited:hover, .btn-primary:visited:focus {
  background: #00B69F;
  color: rgb(255, 255, 255);
  transition: background-color 300ms linear, color 300ms linear;
}

.btn-alternative, .btn-alternative:visited {
  background: #00B69F;
  transition: background-color 300ms linear, color 300ms linear;
}
.btn-alternative:hover, .btn-alternative:focus, .btn-alternative:visited:hover, .btn-alternative:visited:focus {
  background: #125B98;
  transition: background-color 300ms linear, color 300ms linear;
}

form {
  margin-bottom: 2rem;
}

input[type=text],
input[type=date],
input[type=datetime],
input[type=email],
input[type=number],
input[type=search],
input[type=time],
input[type=url],
input[type=password],
textarea,
select {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 7px;
  border: none;
  border-bottom: 1px solid #ddd;
  background: #fff;
  height: 45px;
}

textarea {
  min-height: 90px;
  resize: none;
  overflow: hidden;
}

ul {
  list-style: disc;
  margin: 0 0 1.2rem 0;
}

li {
  margin-bottom: 0;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

.icon,
.theme-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
}

body {
  overflow-x: hidden;
}

section {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
}
@media (min-width: 768px) {
  section {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
@media (min-width: 992px) {
  section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 1200px) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1710px) {
  section {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

section .less-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  section .less-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  section .less-padding {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
@media (min-width: 1200px) {
  section .less-padding {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
@media (min-width: 1710px) {
  section .less-padding {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0 0 1rem;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.7;
  margin: 0;
  padding: 0;
}

blockquote cite {
  display: block;
  font-style: normal;
  font-weight: 600;
  margin-top: 0.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #eee;
  text-decoration: none;
}

blockquote {
  quotes: "" "";
}

q {
  quotes: "“" "”" "‘" "’";
}

blockquote:before,
blockquote:after {
  content: "";
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

table {
  text-align: center;
  margin: 0 0 1.5em;
  width: 100%;
}

thead th {
  background: #F8F8F8;
  color: #fff;
  border-bottom: 2px solid #bbb;
  padding-bottom: 0.5em;
}

th {
  padding: 0.4em;
}

tr {
  border-bottom: 1px solid #eee;
}

td {
  padding: 0.4em;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

body, html {
  font: 400 14px/1.43 "Work Sans", sans-serif;
  color: #202020;
}
@media (min-width: 768px) {
  body, html {
    font-size: 16px;
  }
}
@media (min-width: 1710px) {
  body, html {
    font-size: 18px;
  }
}

a, a:visited {
  font-weight: inherit;
  text-decoration: none;
  color: #125B98;
  transition: 0.32s color;
}
a:hover, a:focus, a:visited:hover, a:visited:focus {
  text-decoration: none;
  color: #07243d;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.25;
  font-family: "Work Sans", sans-serif;
  color: #1D77C2;
}

h1, .h1 {
  position: relative;
  color: #202020;
  margin-bottom: 40px;
  font-size: 28px;
  letter-spacing: 0.7px;
  line-height: 1.21;
  font-weight: 500;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 32px;
    letter-spacing: 0.8px;
  }
}
@media (min-width: 992px) {
  h1, .h1 {
    font-size: 36px;
    letter-spacing: 0.9px;
    margin-bottom: 25px;
  }
}
@media (min-width: 1200px) {
  h1, .h1 {
    letter-spacing: 1px;
    font-size: 40px;
  }
}
@media (min-width: 1710px) {
  h1, .h1 {
    font-size: 52px;
    letter-spacing: 1.3px;
    margin-bottom: 30px;
  }
}

h2, .h2 {
  position: relative;
  color: #202020;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  margin-bottom: 15px;
  letter-spacing: 0.6px;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  h2, .h2 {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 30px;
    letter-spacing: 0.7px;
  }
}
@media (min-width: 1710px) {
  h2, .h2 {
    font-size: 36px;
    letter-spacing: 0.9px;
    margin-bottom: 25px;
  }
}

h3, .h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 18px;
    letter-spacing: 0.3px;
  }
}
@media (min-width: 992px) {
  h3, .h3 {
    font-size: 20px;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 1710px) {
  h3, .h3 {
    font-size: 22px;
    margin-bottom: 20px;
    letter-spacing: 0px;
  }
}

h4, .h4 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.75em;
}

h5, h6, .h5, .h6 {
  font-size: 1rem;
  margin-bottom: 0.75em;
}

strong {
  font-weight: 600;
}

.extra-text {
  position: relative;
  color: rgb(134, 134, 134);
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.008rem;
  text-transform: uppercase;
}
.extra-text:before {
  content: "";
  border-style: solid;
  border-width: 0 0 1.25rem 1.1rem;
  border-color: transparent transparent #a1c517 transparent;
  position: absolute;
  left: -1.25rem;
  top: -1.25rem;
}

p {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  p {
    margin-bottom: 35px;
  }
}
@media (min-width: 1710px) {
  p {
    margin-bottom: 40px;
  }
}
p:last-child {
  margin-bottom: 0;
}

p.cursive-text {
  font-style: italic;
  font-weight: 500;
}

.white-text {
  color: rgb(255, 255, 255);
}

.small-text {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

section#imprint, section#privacy-policy {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  section#imprint, section#privacy-policy {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media (min-width: 1200px) {
  section#imprint, section#privacy-policy {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 1710px) {
  section#imprint, section#privacy-policy {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

/**
Here can be any style modifications for Swiper
 */
html {
  scroll-behavior: smooth;
}

header {
  position: sticky;
  top: 0;
  z-index: 150;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header-top-line {
  background-color: #125B98;
  width: 100%;
  padding: 0px 15px;
  height: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-top-line .small-text {
  opacity: 1;
  transition: opacity 400ms linear;
}

.header-main {
  height: 70px;
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .header-main {
    height: 80px;
  }
}
@media (min-width: 1710px) {
  .header-main {
    height: 100px;
  }
}

.header-logo {
  width: 130px;
  height: 40px;
}
@media (min-width: 992px) {
  .header-logo {
    margin-right: 50px;
  }
}
@media (min-width: 1200px) {
  .header-logo {
    margin-right: 60px;
  }
}
@media (min-width: 1710px) {
  .header-logo {
    width: 165px;
    height: 50px;
    margin-right: 90px;
  }
}
.header-logo svg {
  width: 130px;
  height: 40px;
  transform: scale(1);
  transition: transform 400ms ease-in-out;
}
@media (min-width: 1710px) {
  .header-logo svg {
    width: 165px;
    height: 50px;
  }
}
.header-logo:hover svg, .header-logo:focus svg {
  transform: scale(1.1);
  transition: transform 400ms ease-in-out;
}

.main-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 70px;
  width: 100vw;
  right: -100vw;
  bottom: 0;
  background-color: #fff;
  z-index: 20;
  opacity: 0;
  transition: transform 400ms ease-in-out, opacity 300ms linear;
  border-top: 1px solid #f5f5f5;
}
@media (min-width: 992px) {
  .main-navigation {
    position: static;
    display: block;
    background-color: transparent;
    z-index: auto;
    transform: none;
    opacity: 1;
    width: auto;
    border-top: none;
  }
}
.main-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
@media (min-width: 992px) {
  .main-navigation ul {
    text-align: left;
  }
}
.main-navigation ul > li:not(:last-child) {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .main-navigation ul > li:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .main-navigation ul > li:not(:last-child) {
    margin-right: 50px;
  }
}
@media (min-width: 1710px) {
  .main-navigation ul > li:not(:last-child) {
    margin-right: 65px;
  }
}
.main-navigation__item, .main-navigation__item:visited {
  color: #202020;
  font-weight: 500;
}
@media (max-width: 991px) {
  .main-navigation__item, .main-navigation__item:visited {
    font-size: 30px;
    line-height: 1.17;
  }
}
@media (max-width: 767px) {
  .main-navigation__item, .main-navigation__item:visited {
    font-size: 24px;
  }
}
.main-navigation__item:hover, .main-navigation__item:visited:hover {
  color: #1D77C2;
}
.main-navigation__item.active-link, .main-navigation__item:visited.active-link {
  color: #1D77C2;
}
.main-navigation__item.active-link:hover, .main-navigation__item.active-link:focus, .main-navigation__item:visited.active-link:hover, .main-navigation__item:visited.active-link:focus {
  color: #1D77C2;
}
.main-navigation__item:focus, .main-navigation__item:visited:focus {
  color: #202020;
}
.main-navigation.open {
  transform: translateX(-100vw);
  opacity: 1;
  transition: transform 400ms ease-in-out, opacity 300ms linear;
}

.header-contacts, .header-contacts:visited {
  color: #202020;
  font-size: 18px;
  letter-spacing: 0.45px;
  line-height: 1.2;
  font-weight: 500;
}
.header-contacts svg, .header-contacts:visited svg {
  width: 25px;
  height: 25px;
  fill: #202020;
  transition: fill 300ms linear, transform 400ms ease-in-out;
  transform: scale(1);
}
@media (min-width: 768px) {
  .header-contacts svg, .header-contacts:visited svg {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 992px) {
  .header-contacts svg, .header-contacts:visited svg {
    margin-right: 20px;
    width: 18px;
    height: 18px;
  }
}
@media (min-width: 1710px) {
  .header-contacts svg, .header-contacts:visited svg {
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 992px) {
  .header-contacts, .header-contacts:visited {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1710px) {
  .header-contacts, .header-contacts:visited {
    font-size: 20px;
    letter-spacing: 0.5px;
  }
}
.header-contacts:hover, .header-contacts:focus, .header-contacts:visited:hover, .header-contacts:visited:focus {
  color: #1D77C2;
}
.header-contacts:hover svg, .header-contacts:focus svg, .header-contacts:visited:hover svg, .header-contacts:visited:focus svg {
  fill: #1D77C2;
  transition: fill 300ms linear, transform 400ms ease-in-out;
  transform: scale(1.1);
}
@media (max-width: 991px) {
  .header-contacts.whatsapp-icon, .header-contacts:visited.whatsapp-icon {
    margin-right: 15px;
  }
}
@media (min-width: 992px) {
  .header-contacts.whatsapp-icon svg, .header-contacts:visited.whatsapp-icon svg {
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1710px) {
  .header-contacts.whatsapp-icon svg, .header-contacts:visited.whatsapp-icon svg {
    width: 22px;
    height: 22px;
  }
}

.mobile-toggle {
  width: 30px;
  height: 30px;
  background-color: #1D77C2;
  padding: 9px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: 30px;
  transition: background-color 300ms linear;
}
@media (min-width: 768px) {
  .mobile-toggle {
    width: 40px;
    height: 40px;
    padding: 12px 10px;
  }
}
.mobile-toggle__line {
  display: block;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  height: 2px;
  opacity: 1;
}
.mobile-toggle.open {
  background-color: transparent;
  transition: background-color 300ms linear;
  background-image: url("/build/images/icons/cancel.svg");
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .mobile-toggle.open {
    background-size: 24px 24px;
  }
}
.mobile-toggle.open .mobile-toggle__line {
  opacity: 0;
}

.progress-bar__container {
  width: 100%;
  max-height: 0px;
  height: 5px;
  transition: max-height 400ms ease-in-out;
  background-color: #fff;
}

#progressBar {
  height: 100%;
  width: 0;
  background-color: #125B98;
}

.header-top-line {
  max-height: 30px;
  transition: max-height 400ms ease-in-out;
}

header.scroll .header-top-line {
  max-height: 0px;
  transition: max-height 400ms ease-in-out;
}
header.scroll .header-top-line .small-text {
  opacity: 0;
  transition: opacity 300ms linear;
}
header.scroll .progress-bar__container {
  max-height: 7px;
  transition: max-height 400ms ease-in-out;
}

.hero {
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
}
@media (min-width: 576px) {
  .hero {
    height: 650px;
  }
}
@media (min-width: 768px) {
  .hero {
    height: 700px;
  }
}
@media (min-width: 992px) {
  .hero {
    height: calc(100vh - 140px);
  }
}
@media (min-width: 1710px) {
  .hero {
    height: calc(100vh - 180px);
  }
}
.hero img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero__text-block {
  padding: 20px 15px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(18, 91, 152, 0.7);
  z-index: 10;
}
@media (min-width: 576px) {
  .hero__text-block {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .hero__text-block {
    width: 83%;
    padding: 30px 45px;
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  .hero__text-block {
    padding: 40px 50px 60px 50px;
    max-width: 900px;
  }
}
@media (min-width: 1200px) {
  .hero__text-block {
    padding: 50px 70px 70px 70px;
    max-width: 1000px;
  }
}
@media (min-width: 1710px) {
  .hero__text-block {
    padding: 60px 100px 90px 100px;
    max-width: 1370px;
  }
}
.hero h1 {
  text-align: center;
  font-weight: 500;
}
.hero__list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
@media (min-width: 992px) {
  .hero__list {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}
.hero__list li {
  margin-bottom: 30px;
  position: relative;
}
@media (min-width: 992px) {
  .hero__list li {
    line-height: 1.42;
    font-size: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .hero__list li {
    font-size: 22px;
    letter-spacing: 0.55px;
  }
}
@media (min-width: 1710px) {
  .hero__list li {
    font-size: 24px;
    letter-spacing: 0.6px;
  }
}
.hero__list li:nth-child(1):after {
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  position: absolute;
  bottom: -17px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 992px) {
  .hero__list li:nth-child(1):after {
    display: none;
  }
}
@media (min-width: 992px) {
  .hero__list li:nth-child(1) {
    padding-right: 25px;
  }
}
@media (min-width: 1200px) {
  .hero__list li:nth-child(1) {
    padding-right: 30px;
  }
}
@media (min-width: 1710px) {
  .hero__list li:nth-child(1) {
    padding-right: 40px;
  }
}
.hero__list li:nth-child(2):after {
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  position: absolute;
  bottom: -17px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 992px) {
  .hero__list li:nth-child(2):after {
    display: none;
  }
}
@media (min-width: 992px) {
  .hero__list li:nth-child(2) {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media (min-width: 1200px) {
  .hero__list li:nth-child(2) {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1710px) {
  .hero__list li:nth-child(2) {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.hero__list li:nth-child(3) {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .hero__list li:nth-child(3) {
    padding-left: 25px;
  }
}
@media (min-width: 1200px) {
  .hero__list li:nth-child(3) {
    padding-left: 30px;
  }
}
@media (min-width: 1710px) {
  .hero__list li:nth-child(3) {
    padding-left: 40px;
  }
}
.hero .mouse-icon-container {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%);
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.hero .mouse-icon-container svg {
  position: absolute;
  top: 15%;
  left: 50%;
  width: 20px;
  height: 30px;
}

.mouse-icon {
  animation-name: bounce-mouse;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes bounce-mouse {
  0% {
    transform: translate(-50%, 0px);
  }
  33% {
    transform: translate(-50%, -8px);
  }
  66% {
    transform: translate(-50%, 5px);
  }
  85% {
    transform: translate(-50%, 0px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
}
.about h2 {
  margin-left: auto;
  margin-right: auto;
}
.about__desc {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .about__desc {
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .about__desc {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .about__desc {
    max-width: 70%;
    margin-bottom: 45px;
  }
}
@media (min-width: 1710px) {
  .about__desc {
    max-width: 66%;
    margin-bottom: 60px;
  }
}
.about__main-icon {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
.about__main-icon svg {
  width: 30px;
  height: 30px;
}
@media (min-width: 992px) {
  .about__main-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 30px;
  }
  .about__main-icon svg {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1200px) {
  .about__main-icon {
    width: 45px;
    height: 45px;
  }
  .about__main-icon svg {
    width: 45px;
    height: 45px;
  }
}
@media (min-width: 1710px) {
  .about__main-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 40px;
  }
  .about__main-icon svg {
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 992px) {
  .about__cards {
    display: flex;
    align-items: center;
  }
}
.about__cards--item-title {
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media (min-width: 1710px) {
  .about__cards--item-title {
    font-size: 20px;
    letter-spacing: 0.5px;
  }
}
.about__cards--item.swiper-slide {
  background: #125B98;
  padding: 25px 20px;
  width: 280px;
}
@media (max-width: 575px) {
  .about__cards--item.swiper-slide {
    height: 244px;
  }
}
@media (min-width: 576px) {
  .about__cards--item.swiper-slide {
    width: 380px;
    padding: 30px 25px;
  }
}
@media (min-width: 768px) {
  .about__cards--item.swiper-slide {
    width: 400px;
    padding: 35px 30px;
  }
}
@media (min-width: 992px) {
  .about__cards--item.swiper-slide {
    padding: 45px 25px;
    width: calc(33% + 15px);
  }
}
@media (min-width: 1200px) {
  .about__cards--item.swiper-slide {
    padding: 50px 25px;
  }
}
@media (min-width: 1710px) {
  .about__cards--item.swiper-slide {
    padding: 80px 65px;
  }
}
.about__cards--item.swiper-slide svg {
  width: 25px;
  height: 25px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media (min-width: 768px) {
  .about__cards--item.swiper-slide svg {
    width: 30px;
    height: 30px;
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .about__cards--item.swiper-slide svg {
    width: 35px;
    height: 35px;
    margin-bottom: 25px;
  }
}
@media (min-width: 1200px) {
  .about__cards--item.swiper-slide svg {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1710px) {
  .about__cards--item.swiper-slide svg {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
  }
}
.about__cards--item.slide-two {
  background: #1D77C2;
  padding: 25px 20px;
}
@media (min-width: 576px) {
  .about__cards--item.slide-two {
    width: 380px;
    padding: 30px 25px;
  }
}
@media (min-width: 768px) {
  .about__cards--item.slide-two {
    width: 400px;
    padding: 35px 30px;
  }
}
@media (min-width: 992px) {
  .about__cards--item.slide-two {
    width: calc(33% - 15px);
    padding: 70px 20px;
  }
}
@media (min-width: 1200px) {
  .about__cards--item.slide-two {
    padding: 80px 20px;
  }
}
@media (min-width: 1710px) {
  .about__cards--item.slide-two {
    padding: 130px 50px;
  }
}
@media (max-width: 991px) {
  .about [data-aos^=fade][data-aos^=fade].aos-init {
    transform: none !important;
    opacity: 1 !important;
  }
}

[data-aos=fade-down] {
  transform: translate3d(0, 40px, 0) !important;
}

[data-aos=fade-left] {
  transform: translate3d(40px, 0, 0) !important;
}

[data-aos=fade-down].aos-animate {
  transform: translateZ(0) !important;
}

[data-aos=fade-left].aos-animate {
  transform: translateX(0) !important;
}

.benefits {
  padding-top: 0;
  position: relative;
}
@media (min-width: 992px) {
  .benefits {
    padding-bottom: 160px;
  }
}
@media (min-width: 1710px) {
  .benefits {
    padding-bottom: 210px;
  }
}
.benefits > .container:first-child {
  overflow: hidden;
}
.benefits__image {
  width: 100%;
}
@media (min-width: 992px) {
  .benefits__image {
    margin-right: 45px;
    height: 500px;
    min-width: 275px;
    width: 275px;
  }
}
@media (min-width: 1200px) {
  .benefits__image {
    margin-right: 60px;
    min-width: 370px;
    width: 370px;
    height: 590px;
  }
}
@media (min-width: 1710px) {
  .benefits__image {
    margin-right: 100px;
    height: 700px;
    min-width: 550px;
    width: 550px;
  }
}
.benefits__image img {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.benefits__image picture {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.benefits p.benefits__desc {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .benefits p.benefits__desc {
    margin-bottom: 40px;
  }
}
.benefits__list {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .benefits__list {
    margin-top: 45px;
  }
}
@media (min-width: 992px) {
  .benefits__list {
    margin-top: 50px;
  }
}
@media (min-width: 1200px) {
  .benefits__list {
    margin-top: 55px;
  }
}
@media (min-width: 1710px) {
  .benefits__list {
    margin-top: 75px;
  }
}
.benefits__list--item {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .benefits__list--item {
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .benefits__list--item {
    margin-bottom: 40px;
    padding-left: 40px;
  }
}
@media (min-width: 1200px) {
  .benefits__list--item {
    padding-left: 50px;
  }
}
@media (min-width: 1710px) {
  .benefits__list--item {
    margin-bottom: 50px;
    padding-left: 70px;
  }
}
.benefits__list--item:last-child {
  margin-bottom: 0;
}
.benefits__list--item h3 {
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: 600;
  padding-left: 45px;
  position: relative;
}
@media (min-width: 992px) {
  .benefits__list--item h3 {
    font-size: 18px;
    letter-spacing: 0.45px;
    margin-bottom: 20px;
    padding-left: 0;
  }
}
.benefits__list--item > h3:before {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #1D77C2;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
@media (min-width: 992px) {
  .benefits__list--item > h3:before {
    left: -45px;
  }
}
@media (min-width: 1200px) {
  .benefits__list--item > h3:before {
    left: -50px;
    width: 35px;
    height: 35px;
    border: 3px solid #1D77C2;
  }
}
@media (min-width: 1710px) {
  .benefits__list--item > h3:before {
    left: -70px;
    width: 50px;
    height: 50px;
    border: 4px solid #1D77C2;
  }
}
.benefits__list--item:nth-child(1) > h3:before {
  content: "1";
  color: #fff;
  background: #1D77C2;
}
.benefits__list--item:nth-child(2) > h3:before {
  content: "2";
}
.benefits__list--item:nth-child(3) > h3:before {
  content: "3";
}
.benefits__small-cards {
  background-color: #1D77C2;
  width: 100%;
  margin-top: 60px;
  background-image: url("/build/images/background.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .benefits__small-cards {
    margin-top: 65px;
  }
}
@media (min-width: 992px) {
  .benefits__small-cards {
    background: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: -90px;
    margin-top: 0;
  }
}
@media (min-width: 1710px) {
  .benefits__small-cards {
    bottom: -100px;
  }
}
.benefits__small-cards--wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
}
@media (min-width: 992px) {
  .benefits__small-cards--wrapper {
    background-color: #1D77C2;
    background-image: url("/build/images/background.png");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 40px 25px;
    height: 180px;
  }
}
@media (min-width: 1200px) {
  .benefits__small-cards--wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1710px) {
  .benefits__small-cards--wrapper {
    height: 200px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
.benefits__small-cards--items {
  background: #fff;
  padding: 20px 15px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .benefits__small-cards--items {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .benefits__small-cards--items {
    padding: 30px 25px;
    width: 280px;
    height: 100%;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .benefits__small-cards--items {
    padding: 35px 30px;
    width: 329px;
    font-size: 18px;
  }
}
@media (min-width: 1710px) {
  .benefits__small-cards--items {
    padding: 40px 35px;
    width: 483px;
    font-size: 20px;
  }
}
.benefits__small-cards--items svg {
  margin-right: 15px;
  width: 30px;
  height: 30px;
}
@media (min-width: 992px) {
  .benefits__small-cards--items svg {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1710px) {
  .benefits__small-cards--items svg {
    width: 40px;
    height: 40px;
  }
}
.benefits__small-cards--items:last-child {
  margin-bottom: 0;
}

.services {
  padding-top: 0;
}
@media (min-width: 992px) {
  .services {
    padding-top: 160px;
    background: #F8F8F8;
  }
}
@media (min-width: 1710px) {
  .services {
    padding-top: 210px;
  }
}
.services .btn + .btn, .services input[type=button] + .btn,
.services input[type=submit] + .btn, .services .btn + input[type=button], .services input[type=button] + input[type=button],
.services input[type=submit] + input[type=button],
.services .btn + input[type=submit],
.services input[type=button] + input[type=submit],
.services input[type=submit] + input[type=submit] {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .services .btn + .btn, .services input[type=button] + .btn,
.services input[type=submit] + .btn, .services .btn + input[type=button], .services input[type=button] + input[type=button],
.services input[type=submit] + input[type=button],
.services .btn + input[type=submit],
.services input[type=button] + input[type=submit],
.services input[type=submit] + input[type=submit] {
    margin-left: 15px;
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .services .btn + .btn, .services input[type=button] + .btn,
.services input[type=submit] + .btn, .services .btn + input[type=button], .services input[type=button] + input[type=button],
.services input[type=submit] + input[type=button],
.services .btn + input[type=submit],
.services input[type=button] + input[type=submit],
.services input[type=submit] + input[type=submit] {
    margin-top: 40px;
  }
}
@media (min-width: 1710px) {
  .services .btn + .btn, .services input[type=button] + .btn,
.services input[type=submit] + .btn, .services .btn + input[type=button], .services input[type=button] + input[type=button],
.services input[type=submit] + input[type=button],
.services .btn + input[type=submit],
.services input[type=button] + input[type=submit],
.services input[type=submit] + input[type=submit] {
    margin-left: 30px;
    margin-top: 50px;
  }
}
.services__title, .services__desc {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .services__title, .services__desc {
    max-width: 80%;
  }
}
@media (min-width: 1200px) {
  .services__title, .services__desc {
    max-width: 70%;
  }
}
@media (min-width: 1710px) {
  .services__title, .services__desc {
    max-width: 66%;
  }
}
.services p.services__desc {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .services p.services__desc {
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .services p.services__desc {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .services p.services__desc {
    margin-bottom: 45px;
  }
}
@media (min-width: 1710px) {
  .services p.services__desc {
    margin-bottom: 60px;
  }
}
.services__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 25px 40px;
  height: 100%;
}
@media (min-width: 992px) {
  .services__item {
    border: none;
    padding: 45px 20px;
  }
}
@media (min-width: 1200px) {
  .services__item {
    padding: 50px 25px;
  }
}
@media (min-width: 1710px) {
  .services__item {
    padding: 60px 30px;
  }
}
.services__item svg {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .services__item svg {
    margin-bottom: 25px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1200px) {
  .services__item svg {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1710px) {
  .services__item svg {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
  }
}
.services__item h3 {
  color: #202020;
  font-weight: 600;
}
@media (min-width: 992px) {
  .services__item h3 {
    font-weight: 500;
  }
}
.services .services__item-wrapper {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .services .services__item-wrapper {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .services .services__item-wrapper {
    margin-bottom: 30px;
  }
}
@media (min-width: 1710px) {
  .services .services__item-wrapper:nth-child(5) {
    margin-bottom: 0;
  }
}
@media (min-width: 1710px) {
  .services .services__item-wrapper:nth-child(6) {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .services .services__item-wrapper:nth-child(7) {
    margin-bottom: 0;
  }
}
.services .services__item-wrapper:nth-child(8) {
  margin-bottom: 0;
}
.services .btn, .services input[type=button],
.services input[type=submit] {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .services .btn, .services input[type=button],
.services input[type=submit] {
    margin-top: 35px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .services .btn, .services input[type=button],
.services input[type=submit] {
    margin-top: 40px;
  }
}
@media (min-width: 1710px) {
  .services .btn, .services input[type=button],
.services input[type=submit] {
    margin-top: 50px;
  }
}
@media (max-width: 1709px) {
  .services [data-aos] {
    transition-delay: 0s !important;
  }
}

@media (max-width: 991px) {
  [data-aos] {
    transition-delay: 0s !important;
  }
}

@media (max-width: 991px) {
  .blockquote {
    padding-top: 0;
  }
}
.blockquote__image {
  width: 100%;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .blockquote__image {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .blockquote__image {
    margin-bottom: 0px;
  }
}
.blockquote h2 {
  margin-bottom: 30px;
}
@media (min-width: 1710px) {
  .blockquote h2 {
    margin-bottom: 60px;
  }
}

.experience {
  background-color: #125B98;
}
.experience__card-wrapper {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .experience__card-wrapper {
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .experience__card-wrapper {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .experience__card-wrapper {
    margin-top: 45px;
  }
}
@media (min-width: 1710px) {
  .experience__card-wrapper {
    margin-top: 60px;
  }
}
.experience__card-wrapper > div {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .experience__card-wrapper > div {
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .experience__card-wrapper > div {
    margin-bottom: 0px;
  }
}
.experience__card-wrapper > div:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .experience__card-wrapper > div:nth-last-child(2) {
    margin-bottom: 0;
  }
}
.experience__card-item {
  background: #fff;
  padding: 20px 30px;
  width: 100%;
}
@media (min-width: 1710px) {
  .experience__card-item {
    padding: 40px 20px;
  }
}
@media (min-width: 1200px) {
  .experience__card-item {
    padding: 50px 25px;
  }
}
@media (min-width: 1710px) {
  .experience__card-item {
    padding: 60px 30px;
  }
}
.experience__card-item span {
  display: block;
}
.experience__card-item span:first-child {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .experience__card-item span {
    text-align: center;
  }
}
.experience__card-icon {
  width: 50px;
  height: 50px;
  display: block;
  margin-right: 30px;
}
@media (min-width: 768px) {
  .experience__card-icon {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .experience__card-icon {
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1710px) {
  .experience__card-icon {
    margin-bottom: 25px;
    width: 50px;
    height: 50px;
  }
}
.experience__card-number {
  letter-spacing: 0.85px;
  font-weight: 700;
  font-size: 34px;
  display: inline-block;
}
@media (min-width: 1200px) {
  .experience__card-number {
    font-size: 38px;
    letter-spacing: 0;
  }
}
@media (min-width: 1710px) {
  .experience__card-number {
    font-size: 44px;
    letter-spacing: 0;
  }
}

.map img {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .map img {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .map img {
    margin-top: 0;
  }
}
.map h2 {
  margin-bottom: 30px;
}
@media (min-width: 1710px) {
  .map h2 {
    margin-bottom: 60px;
  }
}
.map a, .map a:visited {
  color: #1D77C2;
}
.map a:hover, .map a:focus, .map a:visited:hover, .map a:visited:focus {
  color: #125B98;
}

.other {
  background: #F8F8F8;
}
@media (max-width: 991px) {
  .other {
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
  }
}
.other h2 {
  margin-bottom: 30px;
}
@media (min-width: 1710px) {
  .other h2 {
    margin-bottom: 60px;
  }
}
.other p:last-of-type {
  margin-bottom: 0;
}
.other img {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .other img {
    margin-bottom: 35px;
  }
}
.other .btn, .other input[type=button],
.other input[type=submit] {
  margin-bottom: 0;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .other .btn, .other input[type=button],
.other input[type=submit] {
    margin-top: 35px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .other .btn, .other input[type=button],
.other input[type=submit] {
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1710px) {
  .other .btn, .other input[type=button],
.other input[type=submit] {
    margin-top: 50px;
  }
}
.other .btn + .btn, .other input[type=button] + .btn,
.other input[type=submit] + .btn, .other .btn + input[type=button], .other input[type=button] + input[type=button],
.other input[type=submit] + input[type=button],
.other .btn + input[type=submit],
.other input[type=button] + input[type=submit],
.other input[type=submit] + input[type=submit] {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .other .btn + .btn, .other input[type=button] + .btn,
.other input[type=submit] + .btn, .other .btn + input[type=button], .other input[type=button] + input[type=button],
.other input[type=submit] + input[type=button],
.other .btn + input[type=submit],
.other input[type=button] + input[type=submit],
.other input[type=submit] + input[type=submit] {
    margin-left: 15px;
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .other .btn + .btn, .other input[type=button] + .btn,
.other input[type=submit] + .btn, .other .btn + input[type=button], .other input[type=button] + input[type=button],
.other input[type=submit] + input[type=button],
.other .btn + input[type=submit],
.other input[type=button] + input[type=submit],
.other input[type=submit] + input[type=submit] {
    margin-left: 0px;
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .other .btn + .btn, .other input[type=button] + .btn,
.other input[type=submit] + .btn, .other .btn + input[type=button], .other input[type=button] + input[type=button],
.other input[type=submit] + input[type=button],
.other .btn + input[type=submit],
.other input[type=button] + input[type=submit],
.other input[type=submit] + input[type=submit] {
    margin-left: 15px;
    margin-top: 40px;
  }
}
@media (min-width: 1710px) {
  .other .btn + .btn, .other input[type=button] + .btn,
.other input[type=submit] + .btn, .other .btn + input[type=button], .other input[type=button] + input[type=button],
.other input[type=submit] + input[type=button],
.other .btn + input[type=submit],
.other input[type=button] + input[type=submit],
.other input[type=submit] + input[type=submit] {
    margin-left: 30px;
    margin-top: 50px;
  }
}

.gallery__wrapper {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .gallery__wrapper {
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .gallery__wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 1710px) {
  .gallery__wrapper {
    margin-top: 60px;
  }
}
.gallery__item.swiper-slide {
  width: 280px;
  height: 280px;
  overflow: hidden;
}
@media (min-width: 380px) {
  .gallery__item.swiper-slide {
    width: 310px;
    height: 310px;
  }
}
@media (min-width: 576px) {
  .gallery__item.swiper-slide {
    width: 350px;
    height: 350px;
  }
}
@media (min-width: 768px) {
  .gallery__item.swiper-slide {
    width: 400px;
    height: 400px;
  }
}
.gallery__item {
  overflow: hidden;
}
@media (min-width: 992px) {
  .gallery__item {
    width: 290px;
    height: 290px;
  }
}
@media (min-width: 1200px) {
  .gallery__item {
    width: 350px;
    height: 350px;
  }
}
@media (min-width: 1710px) {
  .gallery__item {
    width: 530px;
    height: 530px;
  }
}
.gallery__item img {
  transform: scale(1);
  transition: transform 400ms ease-in-out;
}
.gallery__item:hover img, .gallery__item:focus img {
  transform: scale(1.1);
  transition: transform 400ms ease-in-out;
}
@media (min-width: 992px) {
  .gallery__item:nth-child(1) {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .gallery__item:nth-child(2) {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .gallery__item:nth-child(3) {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .gallery__item:nth-child(4) {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .gallery__item:nth-child(5) {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .gallery__item:nth-child(6) {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .gallery .gallery__desc {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .gallery .gallery__desc {
    max-width: 70%;
  }
}
@media (min-width: 1710px) {
  .gallery .gallery__desc {
    max-width: 66%;
  }
}
.gallery p.gallery__desc {
  margin-bottom: 0;
}

.testimonials {
  padding-top: 0;
}
.testimonials p.testimonials__desc {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .testimonials p.testimonials__desc {
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .testimonials p.testimonials__desc {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .testimonials p.testimonials__desc {
    max-width: 70%;
  }
}
@media (min-width: 1710px) {
  .testimonials p.testimonials__desc {
    max-width: 66%;
    margin-bottom: 60px;
  }
}
.testimonials__item.swiper-slide {
  border: 1px solid #e1e1e1;
  padding: 30px 20px;
  width: 280px;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 380px) {
  .testimonials__item.swiper-slide {
    width: 310px;
  }
}
@media (min-width: 576px) {
  .testimonials__item.swiper-slide {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .testimonials__item.swiper-slide {
    padding: 35px 25px;
    width: 400px;
  }
}
@media (min-width: 992px) {
  .testimonials__item.swiper-slide {
    padding: 30px;
    width: 450px;
  }
}
@media (min-width: 1200px) {
  .testimonials__item.swiper-slide {
    width: 535px;
  }
}
@media (min-width: 1710px) {
  .testimonials__item.swiper-slide {
    padding: 40px 35px;
    width: 810px;
  }
}
.testimonials .star-icon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}
.testimonials .star-icon:last-child {
  margin-right: 0;
}
.testimonials .stars-container {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
@media (min-width: 768px) {
  .testimonials .stars-container {
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .testimonials .stars-container {
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}
.testimonials__text {
  font-style: italic;
}
.testimonials__name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .testimonials__name {
    font-size: 18px;
    letter-spacing: 0.2px;
    padding-top: 25px;
  }
}
@media (min-width: 1200px) {
  .testimonials__name {
    font-size: 20px;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 1710px) {
  .testimonials__name {
    font-size: 22px;
    letter-spacing: 0;
    padding-top: 30px;
  }
}
.testimonials .swiper-slide {
  height: 370px;
}
@media (min-width: 576px) {
  .testimonials .swiper-slide {
    height: 345px;
  }
}
@media (min-width: 992px) {
  .testimonials .swiper-slide {
    height: 320px;
  }
}
@media (min-width: 1200px) {
  .testimonials .swiper-slide {
    height: 310px;
  }
}
@media (min-width: 1710px) {
  .testimonials .swiper-slide {
    height: 300px;
  }
}
.testimonials .swiper-pagination-bullets {
  position: static;
  display: none;
}
@media (min-width: 992px) {
  .testimonials .swiper-pagination-bullets {
    display: flex;
    margin-top: 30px;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .testimonials .swiper-pagination-bullets {
    margin-top: 35px;
  }
}
@media (min-width: 1710px) {
  .testimonials .swiper-pagination-bullets {
    margin-top: 50px;
  }
}
.testimonials .swiper-pagination-bullet {
  background: #6A748B;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  margin-right: 6px;
}
@media (min-width: 1710px) {
  .testimonials .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.contacts {
  background: #125B98;
}
.contacts p.white-text {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .contacts p.white-text {
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .contacts p.white-text {
    margin-bottom: 40px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .contacts p.white-text {
    max-width: 70%;
  }
}
@media (min-width: 1710px) {
  .contacts p.white-text {
    margin-bottom: 60px;
    max-width: 66%;
  }
}
.contacts__item {
  background: #fff;
  width: 100%;
  padding: 20px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .contacts__item {
    padding: 25px;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .contacts__item {
    padding: 30px;
    margin-bottom: 0;
    height: 100%;
  }
}
.contacts__item.last-child {
  margin-bottom: 0;
}
.contacts a, .contacts a:visited {
  font-weight: 500;
  color: #202020;
  text-align: center;
  display: block;
}
.contacts a:hover, .contacts a:focus, .contacts a:visited:hover, .contacts a:visited:focus {
  color: #125B98;
}
.contacts a:hover svg, .contacts a:focus svg, .contacts a:visited:hover svg, .contacts a:visited:focus svg {
  transform: scale(1.1);
  transition: transform 300ms ease-in-out, fill linear 300ms;
}
.contacts a span, .contacts a:visited span {
  transition: color 300ms linear;
}
.contacts svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 20px;
  height: 20px;
  fill: #125B98;
  transform: scale(1);
  transition: transform 300ms ease-in-out, fill linear 300ms;
}
@media (min-width: 992px) {
  .contacts svg {
    margin-bottom: 20px;
    width: 25px;
    height: 25px;
  }
}

footer {
  padding: 40px 0px;
}
@media (min-width: 768px) {
  footer {
    padding: 30px 0;
  }
}
footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
@media (min-width: 768px) {
  footer ul {
    display: flex;
    text-align: left;
  }
}
footer ul > li {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  footer ul > li {
    margin-right: 15px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  footer ul > li {
    margin-right: 30px;
  }
}
@media (min-width: 1710px) {
  footer ul > li {
    margin-right: 50px;
  }
}
footer ul > li:last-child {
  margin-bottom: 0px;
  margin-right: 0px;
}
footer p {
  text-align: center;
  margin-bottom: 40px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  footer p {
    text-align: left;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}
footer a, footer a:visited {
  color: #202020;
  font-weight: 500;
}
footer a:hover, footer a:focus, footer a:visited:hover, footer a:visited:focus {
  color: #1D77C2;
}

#maintenance-page {
  color: #fff;
  background-color: #7a8488;
  background-image: url("../images/maintenance/bicycle.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#maintenance-page h1, #maintenance-page h2, #maintenance-page h3, #maintenance-page h4, #maintenance-page h5, #maintenance-page h6, #maintenance-page p, #maintenance-page ul, #maintenance-page li {
  color: #fff;
}
#maintenance-page .content-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(3px);
}
#maintenance-page h1 span {
  font-size: 2rem;
  display: block;
  line-height: 2;
  margin-bottom: 3rem;
}