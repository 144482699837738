.hero {
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;


  @media (min-width: $screen-sm) {
    height: 650px;
  }

  @media (min-width: $screen-md) {
    height: 700px;
  }

  @media (min-width: $screen-lg) {
    height: calc(100vh - 140px);
  }

  @media (min-width: $screen-ws) {
    height: calc(100vh - 180px);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-block {
    padding: 20px 15px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(18,91,152, 0.7);
    z-index: 10;

    @media (min-width: $screen-sm) {
      max-width: 500px;
    }

    @media (min-width: $screen-md) {
      width: 83%;
      padding: 30px 45px;
      max-width: 700px;
    }

    @media (min-width: $screen-lg) {
      padding: 40px 50px 60px 50px;
      max-width: 900px;
    }
    @media (min-width: $screen-xl) {
      padding: 50px 70px 70px 70px;
      max-width: 1000px;
    }

    @media (min-width: $screen-ws) {
      padding: 60px 100px 90px 100px;
      max-width: 1370px;
    }
  }

  h1 {
    text-align: center;
    font-weight: 500;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    @media (min-width: $screen-lg) {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    li {
      margin-bottom: 30px;
      position: relative;
      @media (min-width: $screen-lg) {
        line-height: 1.42;
        font-size: 20px;
        letter-spacing: 0.5px;
        margin-bottom: 0;
      }
      @media (min-width: $screen-xl) {
        font-size: 22px;
        letter-spacing: 0.55px;
      }
      @media (min-width: $screen-ws) {
        font-size: 24px;
        letter-spacing: 0.6px;
      }
    }

    li:nth-child(1) {

      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 1px;
        position: absolute;
        bottom: -17px;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
        @media (min-width: $screen-lg) {
          display: none;
        }
      }

      @media (min-width: $screen-lg) {
        padding-right: 25px;
      }
      @media (min-width: $screen-xl) {
        padding-right: 30px;
      }
      @media (min-width: $screen-ws) {
        padding-right: 40px;
      }

    }

    li:nth-child(2) {

      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 1px;
        position: absolute;
        bottom: -17px;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
        @media (min-width: $screen-lg) {
          display: none;
        }
      }

      @media (min-width: $screen-lg) {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        padding-right: 25px;
        padding-left: 25px;

      }
      @media (min-width: $screen-xl) {
        padding-right: 30px;
        padding-left: 30px;
      }
      @media (min-width: $screen-ws) {
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    li:nth-child(3) {
      margin-bottom: 0;
      @media (min-width: $screen-lg) {
        padding-left: 25px;
      }
      @media (min-width: $screen-xl) {
        padding-left: 30px;
      }
      @media (min-width: $screen-ws) {
        padding-left: 40px;
      }
    }
  }

  .mouse-icon-container {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%);
    width: 60px;
    height: 60px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 15%;
      left: 50%;
      width: 20px;
      height: 30px;
    }
  }
}

.mouse-icon {
  animation-name: bounce-mouse;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes bounce-mouse {
  0% {
    transform: translate(-50%, 0px);
  }
  33% {
    transform: translate(-50%, -8px);
  }
  66% {
    transform: translate(-50%, 5px);
  }
  85% {
    transform: translate(-50%, 0px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
}