$font-family-main: 'Work Sans', sans-serif;
$font-family-alternative: 'Work Sans', sans-serif;

$font-family-title: $font-family-alternative;
$font-family-content: $font-family-main;

$color-primary: #125B98;
$color-secondary: #1D77C2;
$color-soft: #F8F8F8;
$color-alternative: #fff;

$btn-hover-bg: #00B69F;

$text-color-default: #202020;
$text-color-alternative: #fff;
$text-color-soft: rgb(248, 252, 255);
$text-color-light: rgb(255, 255, 255);

$bg-color-alternative: #f8f8f8;
$bg-color-alternative-hover: #f8f8f8;

