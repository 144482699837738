.benefits {
  padding-top: 0;
  position: relative;
  @media (min-width: $screen-lg) {
    padding-bottom: 160px;
  }

  @media (min-width: $screen-ws) {
    padding-bottom: 210px;
  }

  & > .container:first-child {
    overflow: hidden;
  }

  &__image {
    width: 100%;

    @media (min-width: $screen-lg) {
      margin-right: 45px;
      height: 500px;
      min-width: 275px;
      width: 275px;
    }

    @media (min-width: $screen-xl) {
      margin-right: 60px;
      min-width: 370px;
      width: 370px;
      height: 590px;
    }

    @media (min-width: $screen-ws) {
      margin-right: 100px;
      height: 700px;
      min-width: 550px;
      width: 550px;
    }

    img {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    picture {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p.benefits__desc {
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 40px;
    }
  }

  &__list {
    margin-top: 35px;

    @media (min-width: $screen-md) {
      margin-top: 45px;
    }

    @media (min-width: $screen-lg) {
      margin-top: 50px;
    }

    @media (min-width: $screen-xl) {
      margin-top: 55px;
    }

    @media (min-width: $screen-ws) {
      margin-top: 75px;
    }
  }

  &__list--item {
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 35px;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 40px;
      padding-left: 40px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 50px;
    }

    @media (min-width: $screen-ws) {
      margin-bottom: 50px;
      padding-left: 70px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      color: $text-color-default;
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 1.2;
      margin-bottom: 15px;
      font-weight: 600;
      padding-left: 45px;
      position: relative;

      @media (min-width: $screen-lg) {
        font-size: 18px;
        letter-spacing: 0.45px;
        margin-bottom: 20px;
        padding-left: 0;
      }
    }
  }

  &__list--item > h3:before {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $color-secondary;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    @media (min-width: $screen-lg) {
      left: -45px;
    }

    @media (min-width: $screen-xl) {
      left: -50px;
      width: 35px;
      height: 35px;
      border: 3px solid $color-secondary;
    }

    @media (min-width: $screen-ws) {
      left: -70px;
      width: 50px;
      height: 50px;
      border: 4px solid $color-secondary;
    }
  }

  &__list--item:nth-child(1) > h3:before {
    content: '1';
    color: #fff;
    background: $color-secondary;
  }

  &__list--item:nth-child(2) > h3:before {
    content: '2';
  }

  &__list--item:nth-child(3) > h3:before {
    content: '3';
  }

  &__small-cards {
    background-color: $color-secondary;
    width: 100%;
    margin-top: 60px;
    background-image: url("/build/images/background.png");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    @media (min-width: $screen-md) {
      margin-top: 65px;
    }

    @media (min-width: $screen-lg) {
      background: none;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      bottom: -90px;
      margin-top: 0;
    }

    @media (min-width: $screen-ws) {
      bottom: -100px;
    }
  }

  &__small-cards--wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
    @media (min-width: $screen-lg) {
      background-color: $color-secondary;
      background-image: url("/build/images/background.png");
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      padding: 40px 25px;
      height: 180px;
    }
    @media (min-width: $screen-xl) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: $screen-ws) {
      height: 200px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__small-cards--items {
    background: #fff;
    padding: 20px 15px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;

    @media (min-width: $screen-md) {
      margin-bottom: 20px;
    }

    @media(min-width: $screen-lg) {
      padding: 30px 25px;
      width: 280px;
      height: 100%;
      margin-bottom: 0;

    }

    @media(min-width: $screen-xl) {
      padding: 35px 30px;
      width: 329px;
      font-size: 18px;
    }

    @media(min-width: $screen-ws) {
      padding: 40px 35px;
      width: 483px;
      font-size: 20px;
    }

    svg {
      margin-right: 15px;
      width: 30px;
      height: 30px;

      @media(min-width: $screen-lg) {
        width: 35px;
        height: 35px;
      }

      @media(min-width: $screen-ws) {
        width: 40px;
        height: 40px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}