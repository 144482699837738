html {
  scroll-behavior: smooth;
}

header {
  position: sticky;
  top: 0;
  z-index: 150;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.container {
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: $screen-lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header-top-line {
  background-color: $color-primary;
  width: 100%;
  padding: 0px 15px;
  height: 30px;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  .small-text {
    opacity: 1;
    transition: opacity 400ms linear;
  }
}

.header-main {
  height: 70px;
  display: flex;
  align-items: center;

  @media (min-width: $screen-lg) {
    height: 80px;
  }

  @media (min-width: $screen-ws) {
    height: 100px;
  }
}

.header-logo {
  width: 130px;
  height: 40px;

  @media (min-width: $screen-lg) {
    margin-right: 50px;
  }

  @media (min-width: $screen-xl) {
    margin-right: 60px;
  }

  @media (min-width: $screen-ws) {
    width: 165px;
    height: 50px;
    margin-right: 90px;
  }


  svg {
    width: 130px;
    height: 40px;
    transform: scale(1);
    transition: transform 400ms ease-in-out;

    @media (min-width: $screen-ws) {
      width: 165px;
      height: 50px;
    }
  }

  &:hover, &:focus {
    svg {
      transform: scale(1.1);
      transition: transform 400ms ease-in-out;
    }
  }
}

.main-navigation {

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 70px;
  width: 100vw;
  right: -100vw;
  bottom: 0;
  background-color: #fff;
  z-index: 20;
  opacity: 0;
  transition: transform 400ms ease-in-out, opacity 300ms linear;
  border-top: 1px solid #f5f5f5;

  @media (min-width: $screen-lg) {
    position: static;
    display: block;
    background-color: transparent;
    z-index: auto;
    transform: none;
    opacity: 1;
    width: auto;
    border-top: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }

    & > li:not(:last-child) {
      margin-bottom: 20px;
      @media (min-width: $screen-lg) {
        margin-right: 40px;
        margin-bottom: 0;
      }
      @media (min-width: $screen-xl) {
        margin-right: 50px;
      }
      @media (min-width: $screen-ws) {
        margin-right: 65px;
      }

    }
  }

  &__item, &__item:visited {
    color: $text-color-default;
    font-weight: 500;

    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 1.17;
    }

    @media (max-width: 767px) {
      font-size: 24px;
    }

    &:hover {
      color: $color-secondary;
    }

    &.active-link {
      color: $color-secondary;

      &:hover, &:focus {
        color: $color-secondary;
      }
    }

    &:focus {
      color: $text-color-default;
    }
  }

  &.open {
    transform: translateX(-100vw);
    opacity: 1;
    transition: transform 400ms ease-in-out, opacity 300ms linear;
  }
}

.header-contacts, .header-contacts:visited {

  color: $text-color-default;
  font-size: 18px;
  letter-spacing: 0.45px;
  line-height: 1.2;
  font-weight: 500;

  svg {
    width: 25px;
    height: 25px;
    fill: $text-color-default;
    transition: fill 300ms linear, transform 400ms ease-in-out;
    transform: scale(1);

    @media (min-width: $screen-md) {
      width: 30px;
      height: 30px;
    }

    @media (min-width: $screen-lg) {
      margin-right: 20px;
      width: 18px;
      height: 18px;
    }

    @media (min-width: $screen-ws) {
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: $screen-lg) {
    display: flex;
    align-items: center;
  }

  @media (min-width: $screen-ws) {
    font-size: 20px;
    letter-spacing: 0.5px;
  }

  &:hover, &:focus {
    color: $color-secondary;

    svg {
      fill: $color-secondary;
      transition: fill 300ms linear, transform 400ms ease-in-out;
      transform: scale(1.1);
    }
  }

  &.whatsapp-icon {
    @media (max-width: 991px) {
      margin-right: 15px;
    }

    svg {
      @media (min-width: $screen-lg) {
        width: 20px;
        height: 20px;
      }

      @media (min-width: $screen-ws) {
        width: 22px;
        height: 22px;
      }
    }
  }

}

.mobile-toggle {
  width: 30px;
  height: 30px;
  background-color: $color-secondary;
  padding: 9px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: 30px;
  transition: background-color 300ms linear;

  @media (min-width: $screen-md) {
    width: 40px;
    height: 40px;
    padding: 12px 10px;
  }

  &__line {
    display: block;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    height: 2px;
    opacity: 1;
  }

  &.open {
    background-color: transparent;
    transition: background-color 300ms linear;
    background-image: url("/build/images/icons/cancel.svg");
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: $screen-md) {
      background-size: 24px 24px;
    }

    .mobile-toggle__line {
      opacity: 0;
    }
  }
}

.progress-bar__container {
  width: 100%;
  max-height: 0px;
  height: 5px;
  transition: max-height 400ms ease-in-out;
  background-color: #fff;
}

#progressBar {
  height: 100%;
  width: 0;
  background-color: $color-primary;
}

.header-top-line {
  max-height: 30px;
  transition: max-height 400ms ease-in-out;
}

header.scroll {


  .header-top-line {
    max-height: 0px;
    transition: max-height 400ms ease-in-out;

    .small-text {
      opacity: 0;
      transition: opacity 300ms linear;
    }
  }

  .progress-bar__container {
    max-height: 7px;
    transition: max-height 400ms ease-in-out;
  }
}
