@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: normal;
  src: local(''),
  url('../fonts/WorkSans-Regular.woff') format('woff');

}

@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: italic;
  src: local(''),
  url('../fonts/WorkSans-Italic.woff') format('woff');
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: normal;
  src: local(''),
  url('../fonts/WorkSans-Medium.woff') format('woff');

}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: normal;
  src: local(''),
  url('../fonts/WorkSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  font-style: normal;
  src: local(''),
  url('../fonts/WorkSans-Bold.woff') format('woff');
}
