.contacts {
  background: $color-primary;

  p.white-text {
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 35px;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 40px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: $screen-xl) {
      max-width: 70%;
    }

    @media (min-width: $screen-ws) {
      margin-bottom: 60px;
      max-width: 66%;
    }
  }

  &__item {
    background: #fff;
    width: 100%;
    padding: 20px;
    margin-bottom: 15px;

    @media (min-width: $screen-md) {
      padding: 25px;
      margin-bottom: 20px;
    }

    @media (min-width: $screen-lg) {
      padding: 30px;
      margin-bottom: 0;
      height: 100%;
    }

    &.last-child {
      margin-bottom: 0;
    }
  }

  a, a:visited {
    font-weight: 500;
    color: $text-color-default;
    text-align: center;
    display: block;

    &:hover, &:focus {
      color: $color-primary;

      svg {
        transform: scale(1.1);
        transition: transform 300ms ease-in-out, fill linear 300ms;
      }
    }

    span {
      transition: color 300ms linear;
    }
  }

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    width: 20px;
    height: 20px;
    fill: $color-primary;
    transform: scale(1);
    transition: transform 300ms ease-in-out, fill linear 300ms;

    @media (min-width: $screen-lg) {
      margin-bottom: 20px;
      width: 25px;
      height: 25px;
    }
  }
}