.services {
  padding-top: 0;
  @media (min-width: $screen-lg) {
    padding-top: 160px;
    background: $color-soft;
  }

  @media (min-width: $screen-ws) {
    padding-top: 210px;
  }

  .btn + .btn {
    margin-top: 15px;
    @media (min-width: $screen-md) {
      margin-left: 15px;
      margin-top: 35px;
    }

    @media (min-width: $screen-lg) {
      margin-top: 40px;
    }

    @media (min-width: $screen-ws) {
      margin-left: 30px;
      margin-top: 50px;
    }
  }

  &__title, &__desc {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-lg) {
      max-width: 80%;
    }

    @media (min-width: $screen-xl) {
      max-width: 70%;
    }

    @media (min-width: $screen-ws) {
      max-width: 66%;
    }
  }

  p.services__desc {
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 35px;
    }
    @media (min-width: $screen-lg) {
      margin-bottom: 40px;
    }
    @media (min-width: $screen-xl) {
      margin-bottom: 45px;
    }
    @media (min-width: $screen-ws) {
      margin-bottom: 60px;
    }
  }

  &__item {

    background: #fff;
    border: 1px solid #e5e5e5;
    padding: 25px 40px;
    height: 100%;

    @media (min-width: $screen-lg) {
      border: none;
      padding: 45px 20px;
    }

    @media (min-width: $screen-xl) {
      padding: 50px 25px;
    }

    @media (min-width: $screen-ws) {
      padding: 60px 30px;
    }

    svg {
      width: 30px;
      height: 30px;
      margin-bottom: 20px;

      @media (min-width: $screen-lg) {
        margin-bottom: 25px;
        width: 35px;
        height: 35px;
      }

      @media (min-width: $screen-xl) {
        width: 40px;
        height: 40px;
      }

      @media (min-width: $screen-ws) {
        width: 50px;
        height: 50px;
        margin-bottom: 30px;
      }
    }

    h3 {
      color: $text-color-default;
      font-weight: 600;

      @media (min-width: $screen-lg) {
        font-weight: 500;
      }
    }
  }

  .services__item-wrapper {
    margin-bottom: 10px;

    @media (min-width: $screen-sm) {
      margin-bottom: 15px;
    }

    @media (min-width: $screen-md) {
      margin-bottom: 30px;
    }
  }

  .services__item-wrapper:nth-child(5) {
    @media (min-width: $screen-ws) {
      margin-bottom: 0;
    }
  }

  .services__item-wrapper:nth-child(6) {
    @media (min-width: $screen-ws) {
      margin-bottom: 0;
    }
  }

  .services__item-wrapper:nth-child(7) {
    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  .services__item-wrapper:nth-child(8) {
    margin-bottom: 0;
  }

  .btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 30px;
    @media (min-width: $screen-md) {
      margin-top: 35px;
      margin-right: 0;
      margin-left: 0;
    }
    @media (min-width: $screen-lg) {
      margin-top: 40px;
    }
    @media (min-width: $screen-ws) {
      margin-top: 50px;
    }
  }

  [data-aos] {
    @media (max-width: 1709px) {
      transition-delay: 0s !important;
    }
  }
}

[data-aos] {
  @media (max-width: 991px) {
    transition-delay: 0s !important;
  }
}

