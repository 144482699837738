.blockquote {
  @media (max-width: 991px) {
    padding-top: 0;
  }

  &__image {
    width: 100%;
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 40px;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 0px;
    }
  }

  h2 {
    margin-bottom: 30px;
    @media (min-width: $screen-ws) {
      margin-bottom: 60px;
    }
  }
}