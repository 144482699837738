.testimonials {

  padding-top: 0;

  p.testimonials__desc {
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      margin-bottom: 35px;
    }

    @media (min-width: $screen-lg) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }

    @media (min-width: $screen-xl) {
      max-width: 70%;
    }

    @media (min-width: $screen-ws) {
      max-width: 66%;
      margin-bottom: 60px;
    }
  }

  &__item.swiper-slide {
    border: 1px solid #e1e1e1;
    padding: 30px 20px;
    width: 280px;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 380px) {
      width: 310px;
    }

    @media (min-width: $screen-sm) {
      width: 350px;
    }

    @media (min-width: $screen-md) {
      padding: 35px 25px;
      width: 400px;
    }

    @media (min-width: $screen-lg) {
      padding: 30px;
      width: 450px;
    }

    @media (min-width: $screen-xl) {
      width: 535px;
    }

    @media (min-width: $screen-ws) {
      padding: 40px 35px;
      width: 810px;
    }
  }

  .star-icon {
    width: 18px;
    height: 18px;
    margin-right: 7px;

    &:last-child {
      margin-right: 0;
    }
  }

  .stars-container {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    @media (min-width: $screen-md) {
      margin-bottom: 25px;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__text {
    font-style: italic;
  }

  &__name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding-top: 20px;

    @media (min-width: $screen-md) {
      font-size: 18px;
      letter-spacing: 0.2px;
      padding-top: 25px;
    }

    @media (min-width: $screen-xl) {
      font-size: 20px;
      letter-spacing: 0.1px;
    }

    @media (min-width: $screen-ws) {
      font-size: 22px;
      letter-spacing: 0;
      padding-top: 30px;
    }
  }

  .swiper-slide {

    height: 370px;

    @media (min-width: $screen-sm) {
      height: 345px;
    }

    @media (min-width: $screen-lg) {
      height: 320px;
    }

    @media (min-width: $screen-xl) {
      height: 310px;
    }

    @media (min-width: $screen-ws) {
      height: 300px;
    }

  }

  .swiper-pagination-bullets {
    position: static;
    display: none;

    @media (min-width: $screen-lg) {
      display: flex;
      margin-top: 30px;
      justify-content: center;
    }
    @media (min-width: $screen-xl) {
      margin-top: 35px;
    }
    @media (min-width: $screen-ws) {
      margin-top: 50px;
    }
  }

  .swiper-pagination-bullet {
    background: #6A748B;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    margin-right: 6px;

    @media (min-width: $screen-ws) {
      width: 15px;
      height: 15px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}