.btn, .btn:visited {
  display: block;
  text-align: center;
  padding: 16px 25px;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
  width: 100%;
  line-height: 1.2;
  color: $text-color-light;

  @media (min-width: $screen-md) {
    max-width: 250px;
  }

  @media (min-width: $screen-lg) {
    margin-bottom: 35px;
  }

  @media (min-width: $screen-xl) {
    margin-bottom: 40px;
    font-size: 18px;
    letter-spacing: 0.45px;
  }

  @media (min-width: $screen-ws) {
    margin-bottom: 50px;
  }
}

.btn-primary, .btn-primary:visited {
  background: $color-primary;
  transition: background-color 300ms linear, color 300ms linear;

  &:hover, &:focus {
    background: $btn-hover-bg;
    color: $text-color-light;
    transition: background-color 300ms linear, color 300ms linear;
  }
}

.btn-alternative, .btn-alternative:visited {

  background: $btn-hover-bg;
  transition: background-color 300ms linear, color 300ms linear;

  &:hover, &:focus {
    background: $color-primary;
    transition: background-color 300ms linear, color 300ms linear;
  }
}
