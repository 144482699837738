.experience {
  background-color: $color-primary;

  &__card-wrapper {
    margin-top: 30px;
    @media (min-width: $screen-md) {
      margin-top: 35px;
    }
    @media (min-width: $screen-lg) {
      margin-top: 40px;
    }
    @media (min-width: $screen-xl) {
      margin-top: 45px;
    }
    @media (min-width: $screen-ws) {
      margin-top: 60px;
    }

    & > div {
      margin-bottom: 15px;

      @media (min-width: $screen-md) {
        margin-bottom: 30px;
      }

      @media (min-width: $screen-xl) {
        margin-bottom: 0px;
      }
    }

    & > div:last-child {
      margin-bottom: 0;
    }

    & > div:nth-last-child(2) {
      @media (min-width: $screen-md) {
        margin-bottom: 0;
      }
    }
  }

  &__card-item {
    background: #fff;
    padding: 20px 30px;
    width: 100%;

    @media (min-width: $screen-ws) {
      padding: 40px 20px;
    }

    @media (min-width: $screen-xl) {
      padding: 50px 25px;
    }

    @media (min-width: $screen-ws) {
      padding: 60px 30px;
    }

    span {
      display: block;
      &:first-child {
        margin-bottom: 5px;
      }
      
      @media (min-width: $screen-md) {

        text-align: center;
      }
    }
  }

  &__card-icon {
    width: 50px;
    height: 50px;
    display: block;
    margin-right: 30px;


    @media (min-width: $screen-md) {
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: $screen-lg) {
      margin-bottom: 20px;
      width: 40px;
      height: 40px;
    }
    @media (min-width: $screen-ws) {
      margin-bottom: 25px;
      width: 50px;
      height: 50px;
    }
  }

  &__card-number {
    letter-spacing: 0.85px;
    font-weight: 700;
    font-size: 34px;
    display: inline-block;

    @media (min-width: $screen-xl) {
      font-size: 38px;
      letter-spacing: 0;
    }

    @media (min-width: $screen-ws) {
      font-size: 44px;
      letter-spacing: 0;
    }
  }
}